import { useState, useEffect } from "react";
import { Floating } from "./components/Floating";
import { Logo } from "./components/Logo";
import { Slider } from "./components/Slider";
import { SliderProducts } from "./components/SliderProducts";
import { SliderServices } from "./components/SliderServices";
import { Form } from "./components/Form";
import { InteractiveSecurity } from "./components/InteractiveSecurity";
import { Services } from "./components/Services";
import { Instructions } from "./components/Instructions";
import { BestTechnology } from "./components/BestTechnology";
import { Footer } from "./components/Footer";
import { PopUpForm } from "./components/PopUpForm";
import { TextSlider } from "./components/TextSlider";

import "./assets/css/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import PoliticasPrivacidad from "./components/PoliticasPrivacidad";

function App() {
  const [popUpForm, setPopUpForm] = useState(false);
  const brightness = popUpForm ? "fullPage brightness" : "fullPage";
  const [text, setText] = useState("alarma para casa");
  const [getText, setGetText] = useState("");

  const handlerPopUpForm = (text) => {
    setText(text);
    openPopUpFormState();
  };

  const url = window.location.pathname;
  const [data, setData] = useState(null);

  const fetchData = async () =>{
    const data = await fetch('landing-config.json')
    return data.json()
  }
  
  useEffect(() => {
    fetchData()
      .then((response) => {
        setData(response); // Store the resolved value in state
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const openPopUpFormState = () => {
    setPopUpForm(!popUpForm);
  };
  const closePopUpForm = () => {
    if (popUpForm) {
      setPopUpForm(!popUpForm);
    }
  };

  return (
    <div>
      {url === "/politicasdeprivacidad" ? (
        <PoliticasPrivacidad/>
      ):(
        <div>
          {popUpForm ? (
            <div className="background-brightness">
              <PopUpForm text={text} handlerPopUpFormState={openPopUpFormState} />
            </div>
          ) : null}
          <>
          {data !== null ? (
            <div className={brightness} onClick={closePopUpForm}>
              <div className="top-bar">
                <TextSlider />
                <Logo />
                <Floating
                  data={data}
                />
              </div>
              <div className="slider-position">
                {url === "/servicios" ? (
                  <SliderServices
                    setGetText={setGetText}
                    handlerPopUpForm={handlerPopUpForm}
                  />
                ) : url === "/productos" ? (
                  <SliderProducts
                    setGetText={setGetText}
                    handlerPopUpForm={handlerPopUpForm}
                  />
                ) : (
                  <Slider
                    setGetText={setGetText}
                    handlerPopUpForm={handlerPopUpForm}
                  />
                )}
              </div>
              <div className="form-position">
                <Form
                  getText={getText}
                  handlerPopUpForm={handlerPopUpForm}
                />
              </div>
              <div className="interactive-position">
                <InteractiveSecurity />
              </div>
              <div className="divider-position">  </div>
              <div className="instructions-position">
                <Instructions />
              </div>
                  <div className="more-info-position">
                    <a href={`tel:${data.phoneNumber}`}>
                      <button value="Obtener mas información">Más información</button>
                    </a>
                  </div>
              <div className="services-position">
                <Services />
              </div>
              <div className="technology-position">
                <BestTechnology data={data}/>
              </div>
              <div className="footer-position">
                <Footer
                  data={data}
                />
              </div>
            </div>
          ):(<></>)}
          </>
        </div>
      )}
    </div>
  );
}

export default App;
