import React from "react";
import "../assets/css/floating.css";
import phoneIcon from "../assets/images/phoneIcon.svg";

export const Floating = (data) => {
  const phone = data.data.phoneNumber
  const numberIcon = data.data.phoneNumberIcon
  return (
  <div className="floating">
      <div className="floating-button" type="button">
        <img src={phoneIcon} alt="Phone" className="phoneIcon" />
        <a href={`tel:${phone}`}>
          {window.innerWidth < 1001 ? (
            <div>
              <b>{numberIcon}</b>
            </div>
          ):(
            <div>
              <span>Cotizá ahora </span> <b>{numberIcon}</b>
            </div>
          )}
        </a>
      </div>
  </div>)
};
